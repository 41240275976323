"use client";

import { SyncActiveOrganization } from "./SyncActiveOrganization";
//import { SessionLogger } from "./SessionLogger";
import { ReactNode } from "react";
import { useOrganizationList, useUser } from "@clerk/nextjs";

type MembershipData = {
  role: string;
  userId?: string;
  email?: string;
  name?: string;
};


export function ClientWrapper({ children }: { children: ReactNode }) {

  const { userMemberships, isLoaded } = useOrganizationList({
    userMemberships: {
      infinite: true,
    },
  });

  const { user } = useUser();
  
  console.log("Organization List Data:", {
    isLoaded,
    userId: user?.id,
    membershipsCount: userMemberships?.count,
    membershipsData: userMemberships?.data?.map(membership => ({
      organizationId: membership.organization.id,
      organizationName: membership.organization.name,
      memberRole: membership.role,
      memberId: membership.publicUserData?.userId,
      memberEmail: membership.publicUserData?.identifier,
      memberName: membership.publicUserData?.firstName 
        ? `${membership.publicUserData.firstName} ${membership.publicUserData.lastName ?? ''}`
        : undefined,
    })),
    hasNextPage: userMemberships?.hasNextPage,
  });

  const membershipList = userMemberships?.data?.reduce(
    (acc, membership) => ({
      ...acc,
      [membership.organization.id]: {
        role: membership.role,
        userId: membership.publicUserData?.userId,
        email: membership.publicUserData?.identifier,
        name: membership.publicUserData?.firstName 
          ? `${membership.publicUserData.firstName} ${membership.publicUserData.lastName ?? ''}`
          : undefined,
      }
    }),
    {} as Record<string, MembershipData>
  ) ?? {};

  //console.log("Processed Membership List:", membershipList);

  return (
    <>
      {/* <SyncActiveOrganization membership={membershipList} /> */}
      {/* <SessionLogger />  */}
      
      {children}
    </>
  );
}
